main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}

.logo {
    margin-top: 15vh;
    height: 25%
}